import React from "react";
import Navbar from "../components/Welcome/Navbar";

const Terms = () => {
  return (
    <div className="font-graphik bg-[white] min-h-screen">
      <div className="bg-darkBlue">
        <Navbar />
      </div>
      <div>
        <div class="mx-auto max-w-[1400px] justify-center pb-12 lg:bg-white bg-[#f7f7fc] px-3 sm:px-6 md:px-12 xl:px-16 pt-12 grid gri-cols-1 ">
          <div class="w-full">
            <h4 class="font-graphikSemibold text-xl sm:text-2xl text-[#1A1B1C]">
              Nimbus Terms of Service
            </h4>
            <ol class="text-sm max-w-[700px]">
              <li class="my-3">
                <h3 class="mb-2 font-bold text-lg">Acceptance of Terms</h3>
                <p>
                  Welcome to Nimbus Academy! By accessing our website, mobile
                  application, or using our services, you agree to be bound by
                  these Terms of Service ("Terms"). If you do not agree to these
                  Terms, please do not use our services.
                </p>
              </li>
              <li class="my-3">
                <h3 class="mb-2 font-bold text-lg">Description of Services</h3>
                <p>
                  Nimbus Academy provides online learning courses in DevOps,
                  Cloud, and Data Engineering, as well as consulting services in
                  these areas. Our services are detailed on our website and may
                  change or be updated frequently.
                </p>
              </li>
              <li class="my-3">
                <h3 class="mb-2 font-bold text-lg">User Accounts</h3>
                <p>
                  To access certain features of our service, you may need to
                  create an account. You are responsible for maintaining the
                  confidentiality of your account information and for all
                  activities that occur under your account.
                </p>
              </li>
              <li class="my-3">
                <h3 class="mb-2 font-bold text-lg">User Conduct</h3>
                <p>
                  You agree to use our services only for lawful purposes and in
                  a way that does not infringe the rights of, restrict, or
                  inhibit anyone else's use and enjoyment of Nimbus Academy.
                </p>
              </li>
              <li class="my-3">
                <h3 class="mb-2 font-bold text-lg">
                  {" "}
                  Intellectual Property Rights
                </h3>
                <p>
                  All content and materials provided on Nimbus Academy,
                  including course materials, are the property of Nimbus Academy
                  or its content suppliers and are protected by intellectual
                  property laws.
                </p>
              </li>
              <li class="my-3">
                <h3 class="mb-2 font-bold text-lg">Payment Terms</h3>
                <p>
                  Details of the costs and payment terms for our services are
                  available on our website and may be updated from time to time.
                </p>
              </li>
              <li class="my-3">
                <h3 class="mb-2 font-bold text-lg">
                  Cancellation and Refund Policy
                </h3>
                <p>
                  Our cancellation and refund policies for our services are
                  detailed on our website.
                </p>
              </li>
              <li class="my-3">
                <h3 class="mb-2 font-bold text-lg">
                  Modifications to Terms of Service
                </h3>
                <p>
                  Nimbus Academy reserves the right to change or modify these
                  Terms at any time. Your continued use of our services
                  following such changes will indicate your acceptance of the
                  new Terms.
                </p>
              </li>
              <li class="my-3">
                <h3 class="mb-2 font-bold text-lg">Disclaimer of Warranties</h3>
                <p>
                  Our services are provided "as is" without warranty of any
                  kind, either express or implied.
                </p>
              </li>
              <li class="my-3">
                <h3 class="mb-2 font-bold text-lg">Limitation of Liability</h3>
                <p>
                  Nimbus Academy shall not be liable for any indirect,
                  incidental, special, consequential, or punitive damages
                  resulting from the use of, or the inability to use, our
                  services.
                </p>
              </li>
              <li class="my-3">
                <h3 class="mb-2 font-bold text-lg"> Indemnification</h3>
                <p>
                  You agree to indemnify and hold harmless Nimbus Academy and
                  its officers, directors, employees, and agents from any
                  claims, damages, or expenses arising from your use of our
                  services.
                </p>
              </li>
              <li class="my-3">
                <h3 class="mb-2 font-bold text-lg">Governing Law</h3>
                <p>
                  These Terms shall be governed by and construed in accordance
                  with the laws of the jurisdiction in which Nimbus Academy is
                  registered, without regard to its conflict of law provisions.
                </p>
              </li>
              <li class="my-3">
                <h3 class="mb-2 font-bold text-lg">Contact Information</h3>
                <p>
                  If you have any questions about these Terms, please contact us
                  at
                </p>
                <p>Nimbus Global Technologies Limited</p>
                <p>info@nimbusglobal.tech, info@nimbus.school</p>
                <p>71-75 Shelton Street, Covent Garden</p>
                <p>London, United Kingdom, WC2H 9JQ</p>
                <p></p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
