import React, { useState } from "react";
import { useMutation } from "react-query";
import { sendMessage } from "../../services";

//images
import XIcon from "../../assets/images/x-dark.svg";
import Done from "../../assets/images/done.gif";
const ContactModal = ({ setShowModal }) => {
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [message, setMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [detailsSubmitted, setDetailsSubmitted] = useState(false);

  
  const { isLoading, mutate } = useMutation(
    ["send message"],
    (payload) => sendMessage(payload),
    {
      onSuccess: () => {
        setDetailsSubmitted(true);
      },
      onError: (error) => {
        console.log({ error });
        setErrorMessage(error?.response?.data?.message ?? "An error has occurred");
      },
    }
  );

  const handleSendMessage = () => {
    if (!name) {
      setErrorMessage("Full name is required");
      return;
    }
    if (!email) {
      setErrorMessage("Email address is required");
      return;
    }
    if (!message) {
      setErrorMessage("message is required");
      return;
    }
    setErrorMessage("");
    mutate({ name, email, message });
  };
  return (
    <div className="fixed top-0 left-0 w-full h-svh bg-[#00000066] grid place-content-center">
      <div className="w-fit rounded-lg bg-white py-8 lg:py-8">
        <div className="w-[calc(100vw-1rem)] xs:w-[calc(100vw-2rem)] m-0 mx-auto max-w-[800px]  min-h-[100px] px-5 lg:px-8 max-h-[calc(65vh-2.8rem)] overflow-auto relative">
          {detailsSubmitted ? (
            <>
              <div
                onClick={() => setShowModal(false)}
                className="w-fit ml-auto mr-[-10px] p-[.4rem] cursor-pointer"
              >
                <img src={XIcon} alt="close" className="w-4" />
              </div>
              <img
                src={Done}
                alt="done"
                className="w-full max-w-[200px] mx-auto mt-2"
              />
              <h2 className=" text-[#1A1B1C] font-bold text-center font-graphikBold text-2xl xs:text-2xl md:text-3xl">
                Thank you for reaching out!
              </h2>

              <p className="text-[#A3A3A3] font-graphikLight text-center  text-sm mt-3">
                A Nimbus representative will get in touch with you shortly to
                discuss your business needs.
              </p>
            </>
          ) : (
            <>
              <div className="grid grid-cols-[1fr_70px]">
                <h2 className=" text-[#1A1B1C] font-bold font-graphikBold text-2xl xs:text-2xl md:text-3xl">
                  Connect with Nimbus for Business Solutions
                </h2>
                <div
                  onClick={() => setShowModal(false)}
                  className="w-fit ml-auto mr-[-10px] p-[.4rem] cursor-pointer"
                >
                  <img src={XIcon} alt="close" className="w-4" />
                </div>
              </div>

              <p className="text-[#000000B2]  text-sm xs:text-[1rem] mt-3">
                Your success is our priority, and we look forward to the
                opportunity to collaborate with you for a profitable future.
              </p>
              <div className="grid sm:grid-cols-2 mt-2 sm:mt-4 sm:gap-4">
                <div className="mt-4">
                  <label className="text-[#171717] text-[1rem] block mb-1">
                    Full name
                  </label>
                  <input
                    type="text"
                    className="w-full  h-12 rounded-lg border border-[#E5E5E5] shadow-sm bg-white px-3 text-sm text-[#696D71] text-[1rem] outline-none"
                    placeholder="John Dowry"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="mt-4">
                  <label className="text-[#171717] text-[1rem] block mb-1">
                    Email Address
                  </label>
                  <input
                    type="email"
                    className="w-full  h-12 rounded-lg border border-[#E5E5E5] shadow-sm bg-white px-3 text-sm text-[#696D71] text-[1rem] outline-none"
                    placeholder="John.dowry@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-4 sm:mt-6">
                <label className="text-[#171717] text-[1rem] block mb-1">
                  Message
                </label>
                <textarea
                  type="text"
                  className="w-full  h-[9rem] py-3 rounded-lg border border-[#E5E5E5] shadow-sm bg-white px-3 text-sm text-[#696D71] text-[1rem] outline-none"
                  placeholder="Briefly describe your business needs or inquiries"
                  rows={4}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              {errorMessage && (
                <p className="text-red-500  text-sm mt-3">{errorMessage}</p>
              )}
              <button
                onClick={handleSendMessage}
                className="mt-4 w-full rounded-md py-3 bg-blue text-white text-[1rem]"
              >
                {isLoading ? "Loading..." : "Send Message"}
              </button>
              <p className="text-[#A3A3A3] font-graphikLight  text-sm mt-3">
                Your information is secure with us. Nimbus respects your privacy
                and will only use your provided information to contact you
                regarding your business inquiries.
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactModal;
