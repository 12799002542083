import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import MenuOpenIcon from "../../assets/images/menu-open.svg";
import MenuCloseIcon from "../../assets/images/menu-close.svg";

const Navbar = ({ setShowContactModal }) => {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <div className="mx-auto px-3 sm:px-6 md:px-12  xl:px-16 items-center h-[70px] sm:h-[90px] flex justify-between text-white">
        <div className="flex items-center ">
          <img src={Logo} className="w-7 lg:w-8" alt="logo" />
          <h3 className="font-graphikBold ml-2 lg:ml-3 text-[1rem] sm:text-lg md:text-xl lg:text-xl">
            Nimbus Business
          </h3>
        </div>
        <ul className="space-x-5 xl:space-x-7 lg:items-center font-graphik text-md hidden lg:flex">
          {/* <li>
            <a href="#">Customer's Stories</a>
          </li>
          <li>
            <a href="#">Resources</a>
          </li>
          <li>
            <a href="#">Blog</a>
          </li> */}
        </ul>

        <div className="hidden lg:flex space-x-3 lg:items-center">
          <button
            onClick={() => setShowContactModal(true)}
            className="px-5 py-2 bg-blue rounded-md"
          >
            Contact us
          </button>
        </div>

        <div className="block lg:hidden" onClick={() => setShowMenu(true)}>
          <img
            className=" w-5 cursor-pointer"
            src={MenuOpenIcon}
            alt="open menu"
          />
        </div>
      </div>

      {/* mobile view */}

      {showMenu && (
        <div className="text-white navHeightCustom bg-darkBlue top-0 fixed  px-3 sm:px-6 md:px-12  xl:px-16 z-10">
          <div className="mx-auto items-center h-[70px]  sm:h-[90px] flex justify-between">
            <div className="flex items-center ">
              <img src={Logo} className="w-7 lg:w-8" alt="logo" />
              <h3 className="font-graphikBold ml-2 lg:ml-3 text-[1rem] sm:text-lg md:text-xl lg:text-xl">
                Nimbus Business
              </h3>
            </div>
            <div className="block lg:hidden" onClick={() => setShowMenu(false)}>
              <img
                className="w-4 cursor-pointer"
                src={MenuCloseIcon}
                alt="open menu"
              />
            </div>
          </div>
          <ul className="mt-4 space-y-7 font-graphik text-lg">
            {/* <li className="">
              <a href="#">Resources</a>
            </li>
            <li>
              <a href="#">Blog</a>
            </li>
            <li className="">
              <a>Customer's Stories</a>
            </li> */}
          </ul>

          <div className="mt-12 space-y-3">
            <button
              onClick={() => {
                setShowContactModal(true);
                setShowMenu(false);
              }}
              className="px-5 py-3 font-medium w-full bg-blue rounded-md"
            >
              Contact Us
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
