import React from "react";

const SectionTwo = () => {
  return (
    <div className="bg-milk pt-20 pb-20 px-3 sm:px-6 md:px-12 xl:px-16">
      <div className="">
        <h2 className="text-blackVariant2 sm:max-w-xl md:max-w-4xl xl:max-w-5xl  mx-auto  pb-3 text-center font-bold font-graphikBold text-3xl xs:text-3xl sm:text-4xl md:text-4xl xl:text-5xl">
          {/* Learning Modules <br /> Designed By Experts */}
          Scalability, Agility, Intelligence: Tailored Consulting for Every
          Business Need
        </h2>
      </div>

      <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="bg-white rounded-md shadow-md p-6 border-milk border ">
          <h3 className="text-blackVariant2 font-graphikSemibold font-medium text-md lg:text-lg">
            Strategic Advantage
          </h3>
          <p className="text-blackVariant4 text-sm mt-3">
            We offer advanced IT consulting services and tailor-made solutions
            that give your business a competitive edge. 
          </p>
        </div>

        <div className="bg-white rounded-md shadow-md p-6 border-milk border ">
          <h3 className="text-blackVariant2 font-graphikSemibold font-medium text-md lg:text-lg">
            Comprehensive Services
          </h3>
          <p className="text-blackVariant4 text-sm mt-3">
            We covering everything from initial assessment and strategic
            planning to final deployment and maintenance. {" "}
          </p>
        </div>

        <div className="bg-white rounded-md shadow-md p-6 border-milk border ">
          <h3 className="text-blackVariant2 font-graphikSemibold font-medium text-md lg:text-lg">
            Data-Driven Insights
          </h3>
          <p className="text-blackVariant4 text-sm mt-3">
            Using tools like Redshift and Snowflake, they can build data
            lakes, ETL pipelines, and analytics platforms to drive data-driven
            decision-making.
          </p>
        </div>

        <div className="bg-white rounded-md shadow-md p-6 border-milk border ">
          <h3 className="text-blackVariant2 font-graphikSemibold font-medium text-md lg:text-lg">
            Expertise Across Technologies
          </h3>
          <p className="text-blackVariant4 text-sm mt-3">
            We possess deep knowledge of tools and technologies, from cloud
            scaling with AWS to data harnessing with Redshift and Snowflake. 
          </p>
        </div>

        <div className="bg-white rounded-md shadow-md p-6 border-milk border ">
          <h3 className="text-blackVariant2 font-graphikSemibold font-medium text-md lg:text-lg">
            Business Model Reinvention
          </h3>
          <p className="text-blackVariant4 text-sm mt-3">
            Adopting new technology with Nimbus isn't just about implementation;
            it's about transforming your business model for the digital future. 
          </p>
        </div>

        <div className="bg-white rounded-md shadow-md p-6 border-milk border ">
          <h3 className="text-blackVariant2 font-graphikSemibold font-medium text-md lg:text-lg">
            Experienced Partners
          </h3>
          <p className="text-blackVariant4 text-sm mt-3">
            The Nimbus team consists of innovators not just educators. Meaning
            we have the practical experience to not only design solutions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
