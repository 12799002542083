import React from "react";

//images
import SnowflakeImage from "../../assets/images/snowflake.png";
import RedshiftImage from "../../assets/images/redshift.png";
import PartnershipImage from "../../assets/images/partnership.png";
import TechsImage from "../../assets/images/techs.png";
import ServicesImage from "../../assets/images/services.svg";

const SectionFour = () => {
  return (
    <div className="bg-white min-h-[350px] pt-1 pb-20 px-3 sm:px-6 md:px-12 xl:px-16">
      <div className="max-w-[1400px] mx-auto">
        <div className="block sm:grid grid-cols-2 gap-8 max-w-[1400px] mx-auto">
          <div className="mt-20">
            <img src={RedshiftImage} className="w-full" alt="redshift" />
            <h2 className=" text-[#1A1B1C] mt-3 font-bold font-graphikBold text-2xl xs:text-2xl md:text-3xl">
              Extracting valuable insights with Redshift
            </h2>
            <p className="text-[#787D82] sm:max-w-xl md:max-w-xl lg:mx-0  text-sm xs:text-[1rem] mt-3">
              Our proficiency in extracting valuable insights from data is
              exemplified through our strategic utilization of AWS Redshift.
              This data warehousing solution is instrumental in empowering
              businesses to make informed, data-driven decisions.
            </p>
          </div>
          <div className="mt-20">
            <img src={SnowflakeImage} className="w-full" alt="redshift" />
            <h2 className=" text-[#1A1B1C] mt-6 font-bold font-graphikBold text-2xl xs:text-2xl md:text-3xl">
              Business Insights with Snowflake with Nimbus
            </h2>
            <p className="text-[#787D82] sm:max-w-xl md:max-w-xl lg:mx-0  text-sm md:text-[1rem] mt-2">
              Snowflake's unique architecture allows Nimbus to facilitate
              seamless data sharing and analytics. We specialize in configuring
              Snowflake environments that support efficient collaboration and
              analysis across diverse datasets.
            </p>
          </div>
        </div>
        <div className="mt-32 grid  lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
          <div className="">
            <h2 className=" text-[#1A1B1C]  sm:max-w-xl md:max-w-xl mx-auto lg:mx-0 lg:pb-3 text-center lg:text-left font-bold font-graphikBold text-2xl xs:text-3xl sm:text-4xl ">
              Strategic Partnership
            </h2>
            <p className="text-[#787D82] sm:max-w-xl md:max-w-xl mx-auto lg:mx-0  text-sm md:text-[1rem] mt-2 text-center lg:text-left">
              We establish a collaborative partnership by digging deep into your
              business processes.
            </p>
            <p className="text-[#787D82] sm:max-w-xl md:max-w-xl mx-auto lg:mx-0  text-sm md:text-[1rem] mt-2 text-center lg:text-left">
              Through this, we design strategies that align with your vision,
              ensuring a symbiotic relationship between education and
              implementation.
            </p>
          </div>
          <div className="mt-12 lg:mt-0">
            <img
              src={PartnershipImage}
              className="block mx-auto lg:ml-auto w-full max-w-xl xl:max-w-none"
              alt="device"
            />
          </div>
        </div>

        <div className="mt-32 grid  lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
          <div className="order-1 lg:order-2">
            <h2 className=" text-[#1A1B1C]  sm:max-w-xl md:max-w-xl mx-auto lg:mx-0 lg:pb-3 text-center lg:text-left font-bold font-graphikBold text-2xl xs:text-3xl sm:text-4xl">
              Technology Expertise
            </h2>
            <p className="text-[#787D82] sm:max-w-xl md:max-w-xl mx-auto lg:mx-0  text-sm xs:text-[1rem] mt-2 text-center lg:text-left">
              Our proficiency extends across dynamic tools and technologies,
              such as AWS, Kubernetes, Redshift, and Snowflake.
            </p>
            <p className="text-[#787D82] sm:max-w-xl md:max-w-xl mx-auto lg:mx-0  text-sm xs:text-[1rem] mt-2 text-center lg:text-left">
              This diverse skill set allows us to provide robust and scalable
              solutions, guaranteeing a competitive edge in the ever-evolving IT
              landscape.
            </p>
          </div>
          <div className="mt-12 lg:mt-0 order-2 lg:order-1">
            <img
              src={TechsImage}
              className="block mx-auto lg:ml-auto w-full max-w-xl xl:max-w-none"
              alt="device"
            />
          </div>
        </div>

        <div className="mt-32 grid  lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
          <div className="">
            <h2 className=" text-[#1A1B1C]  sm:max-w-xl md:max-w-xl mx-auto lg:mx-0 lg:pb-3 text-center lg:text-left font-bold font-graphikBold text-2xl xs:text-3xl sm:text-4xl">
              End-to-End Services
            </h2>
            <p className="text-[#787D82] sm:max-w-xl md:max-w-xl mx-auto lg:mx-0  text-sm xs:text-[1rem] mt-2 text-center lg:text-left">
              We use these technologies to improve the scalability, portability,
              and efficiency of your applications.
            </p>
            <p className="text-[#787D82] sm:max-w-xl md:max-w-xl mx-auto lg:mx-0  text-sm xs:text-[1rem] mt-2 text-center lg:text-left">
              This ensures that your IT infrastructure is not only agile but
              also capable of adapting to the evolving demands of your business.
            </p>
          </div>
          <div className="mt-12 lg:mt-0 ">
            <img
              src={ServicesImage}
              className="block mx-auto lg:ml-auto w-full max-w-xl xl:max-w-none"
              alt="device"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionFour;
