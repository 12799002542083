//import "../styles/main.css"
import { useState } from "react";
import Navbar from "../components/Welcome/Navbar";
import SectionOne from "../components/Welcome/SectionOne";
import SectionTwo from "../components/Welcome/SectionTwo";
import SectionThree from "../components/Welcome/SectionThree";
import SectionFour from "../components/Welcome/SectionFour";
import Footer from "../components/Welcome/Footer";

import ContactModal from "../components/Welcome/ContactModal";

function Welcome() {
  const [showContactModal, setShowContactModal] = useState(false);
  return (
    <div className="font-graphik bg-[#000000] text-white min-h-screen">
      <Navbar setShowContactModal={setShowContactModal} />
      <SectionOne setShowContactModal={setShowContactModal} />
      <SectionTwo />
      <SectionThree />

      <SectionFour />
      <Footer setShowContactModal={setShowContactModal} />
      {showContactModal && (
        <ContactModal
          showModal={showContactModal}
          setShowModal={setShowContactModal}
        />
      )}
    </div>
  );
}

export default Welcome;
