import React, { useState } from "react";
import { useMutation } from "react-query";
import { sendMessage } from "../../services";
import Logo from "../../assets/images/logo.svg";

const Footer = ({ setShowContactModal }) => {
  const [email, setEmail] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const { isLoading, mutate } = useMutation(
    ["send message"],
    (payload) => sendMessage(payload),
    {
      onSuccess: () => {
        alert("Email address saved successfully");
        setEmail("")
      },
      onError: (error) => {
        console.log({ error });
        setErrorMessage(
          error?.response?.data?.message ?? "An error has occurred"
        );
      },
    }
  );

  const handleSubscribe = () => {
    if (!email) {
      setErrorMessage("Email address is required");
      return;
    }
    mutate({ email });
  };
  return (
    <div className="pt-20 pb-12 px-3 sm:px-6 md:px-12 xl:px-16 bg-[#000]">
      <div className="min-h-[350px] sm:min-h-[400px] lg:min-h-[500px] blueBlackGradient rounded-xl md:rounded-3xl p-4 sm:p-8 pt-16 lg:pt-32">
        <div className="mx-auto max-w-sm xs:max-w-xl ">
          <h2 className="bg-textGradient-customVariant  mx-auto  pb-3 text-center font-bold font-graphikBold text-3xl xs:text-3xl sm:text-4xl md:text-4xl xl:text-5xl">
            {/* Your Journey <br /> Starts here */}
            Scale your Business <br /> with Nimbus
          </h2>
          <p className="text-[#ffffffd5] max-w-[400px] opacity-80 mx-auto  text-md mt-0 text-center">
            Contact us today to explore how our end-to-end consulting solutions
            can transform your business.
          </p>
        </div>
        <button
          onClick={() => setShowContactModal(true)}
          className="w-auto text-md sm:text-md mx-auto  block px-9 py-3 mt-4 bg-blue rounded-md"
        >
          Contact Us
        </button>
      </div>

      <div className="mt-20 md:mt-32 grid grid-cols-1 sm:flex sm:justify-between sm:flex-wrap">
        
        <div className="mb-5 mr-12 max-w-[300px]">
          <h3 className="text-[#aaaeb3] pb-1 text-[.9rem]">Contact us</h3>
          <ul>
            <li className="text-[#787D82] mt-3 text-sm">Contact</li>
            <li className="text-[#787D82] mt-3 text-sm">
              Nimbus Global Technologies Limited
              <br />
              info@nimbusglobal.tech, info@nimbus.school
              <br />
              71-75 Shelton Street, Covent Garden
              <br />
              London, United Kingdom, WC2H 9JQ{" "}
            </li>
          </ul>
        </div>

        <div className="mb-5 mr-12">
          <h3 className="text-[#aaaeb3] pb-1 text-[.9rem]">Legal</h3>
          <ul>
            <li className="text-[#787D82] mt-3 text-sm">
              <a
                href="terms-and-conditions"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use{" "}
              </a>
            </li>
          </ul>
        </div>
        <div className="mb-5 mr-12">
          <h3 className="text-[#aaaeb3] pb-1 text-[.9rem]">Social</h3>
          <ul>
            <li className="text-[#787D82] mt-3 text-sm">
              <a
                href="https://www.linkedin.com/company/nimbus-gblobal/about/"
                target="_blank"
                rel="noreferrer"
              >
                Linkedin
              </a>
            </li>
          </ul>
        </div>
        <div className="max-w-[300px]">
          <div className="flex items-center">
            <img src={Logo} className="w-7 lg:w-8" alt="logo" />
            <h3 className="font-graphikBold ml-2 lg:ml-3 text-lg sm:text-lg ">
              Nimbus Business
            </h3>
          </div>
          <p className="text-[#787D82] text-sm mt-5">
            Get the latest updates about Nimbus’s new features and product
            updates.
          </p>
          {errorMessage && (
            <p className="text-red-500  text-sm mt-3">{errorMessage}</p>
          )}
          <div className="mt-4 grid grid-cols-[1fr_80px] justify-center gap-2 h-10">
            <input
              className="rounded-md px-5 w-full text-black outline-none text-sm"
              type="email"
              placeholder="email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className="w-auto text-sm  block bg-blue rounded-md cursor-pointer"
              type="submit"
              value={isLoading? "Loading...": "Submit"}
              onClick={() => handleSubscribe()}
            />
          </div>
        </div>
      </div>

      <div className="w-full h-[1px] bg-[#E5E7EB] mt-12"></div>

      <div className="flex justify-between flex-wrap items-center">
        {/* <div className="flex items-center mt-4">
          <p className="mr-4 text-[#787D82] text-sm">Security</p>
          <p className="mr-4 text-[#787D82] text-sm">Sitemap</p>
        </div> */}
        <p className="text-[#787D82] text-sm mt-4">
          © 2024 Nimbus Academy. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
