import React from "react";
import DevopsOptions from "../../assets/images/devops-options.svg";

const SectionThree = () => {
  return (
    <div className="bg-darkBlue">
      <div className="grid  lg:grid lg:grid-cols-2 lg:items-center bg-darkBlue px-3 sm:px-6 md:px-12 xl:px-16 pt-20 pb-20">
        <div className=" mt-12  lg:mt-0 order-2 lg:order:1">
          <img
            src={DevopsOptions}
            className="block mx-auto lg:ml-auto w-full max-w-xl"
            alt="device"
          />
        </div>
        <div className="order-1 lg:order-2">
          <h2 className=" text-[#D4D4D4]  sm:max-w-xl md:max-w-xl mx-auto lg:mx-0 pb-3 text-center lg:text-left font-bold font-graphikBold text-3xl xs:text-3xl sm:text-4xl md:text-4xl xl:text-5xl">
            Empowering Your Business with Cutting-Edge Cloud Engineering and Data Solutions
          </h2>
          <p className="text-[#B7B7B7] sm:max-w-xl md:max-w-xl mx-auto lg:mx-0  text-md mt-3 text-center lg:text-left">
             we blend innovation, expertise, and technology to drive your business forward. With a strong foundation in cloud engineering and data management, we offer tailored solutions to elevate your enterprise to new heights.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
