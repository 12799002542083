import React from "react";
import { Route, Routes as Switch, Navigate } from "react-router-dom";


//pages
import Welcome from "../pages/Welcome";
import Terms from "../pages/Terms";


const Routes = () => {
  return (
    <>
      <Switch>
        <Route path="/" element={<Welcome />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
      </Switch>
    </>
  );
};

export default Routes;
