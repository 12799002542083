import React from "react";

//images
import IntroImg from "../../assets/images/intro-img.svg";

const SectionOne = ({ setShowContactModal }) => {
  return (
    <div>
      <div className="  pb-20 block lg:grid lg:grid-cols-2 mt-12 lg:mt-20 lg:space-x-0">
        <div className="px-3 sm:pl-6 md:pl-12 xl:pl-16 lg:mt-20">
          <h1 className="mx-auto max-w-md xs:max-w-md bg-red-300  sm:max-w-xl md:max-w-xl lg:max-w-xl xl:max-w-none lg:mx-0 bg-textGradient-custom pb-1 sm:pb-3 text-left xs:text-center lg:text-left font-bold font-graphikBold text-3xl xs:text-4xl sm:text-5xl md:text-5xl xl:text-6xl">
            Advanced IT Consulting Services at Nimbus
          </h1>
          <p className="mx-auto mx-0 lg:mx-0 text-left  lg:text-left text-md sm:text-md xl:text-lg max-w-sm  sm:max-w-md mt-0 md:mt-3 xs:text-center text-whiteVariant">
            Transform your business with customized solutions & expert guidance
            across cloud, data, and automation.
          </p>
          <button
            onClick={() => setShowContactModal(true)}
            className="mx-auto lg:mx-0 w-full xs:w-auto text-md sm:text-md block px-9 py-3 mt-4 sm:mt-4 bg-blue rounded-md"
          >
            Contact Us
          </button>
        </div>

        {/*  */}
        <div className="ml-auto mt-20 w-full lg:mt-0">
          <img
            className="w-full max-w-[600px] ml-auto lg:max-w-[800px] "
            src={IntroImg}
            alt="intro"
          />
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
